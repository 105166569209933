import logo from './logo.svg';
import './App.css';
import Scan from './components/Scan';
import { Routes, Route, Link } from "react-router-dom";
import Security from './components/Security';
import Nav from './components/Nav';

function App() {
  return (
    <div>
      {/* <Scan /> */}
      <Nav />
      <Routes>
      <Route path="/" element={<Scan />} />
        <Route path="/security" element={<Security />} />
      </Routes>
     
    </div>
  );
}

export default App;
