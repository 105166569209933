import React,{useEffect, useState,useContext} from 'react';
import { Link } from 'react-router-dom';
// import RecordVideo from './RecordVideo';
import Spo2 from '../module1/preview/Screen2-VitalSign1.png'
import HeartRate from '../module1/preview/Screen2-VitalSign2.png'
import BsRate from '../module1/preview/Screen2-VitalSign3.png'
// import Temp from '../module1/preview/Screen2-VitalSign4.png'
import BPressure from '../module1/preview/Screen2-VitalSign5.png'
// import EyeColor from '../module1/preview/Screen2-VitalSign6.png'
import Breathing from '../module1/preview/Screen2-VitalSign7.png'
import Healthy from '../module1/WebAppModule1_Screen5Green.png' 
import Abnormal from '../module1/preview/Screen5-CautionIcon.png' 
import LoadingIcon from '../module1/preview/Screen2-LargerLoadingIcon.gif' 
import HealthyMarker from '../module1/preview/Screen3-HealthyMarker.png' 
import AbnormalMarker from '../module1/preview/Screen5-CautionMarker.png' 
import CautionMarker from '../module1/preview/Screen4-AbnormalMarker.png' 
// import AbnormalMarker from '../module1/preview/Screen5-CautionMarker.png' 
import Caution from '../module1/WebAppModule1_Screen6Yellow.png'
import V2 from '../module1/TabBar-Wellness@2x.png'
import Health from '../module1/TabBar-Healthiness@2x.png'
import Wellness from '../module1/TabBar-Wellness_1@2x.png'
// import 'react-notifications/lib/notifications.css';
// import {NotificationContainer, NotificationManager} from 'react-notifications';

import Smile from  '../module1/WebAppModule1_Screen8PopSmile.png'
// import { useContext } from 'react/cjs/react.production.min';
// import { AuthContext } from '../App';  
// import 

const Security = () => {
    // https://codesandbox.io/s/zhde4?file=/src/App.js
    const [healthstatus, setHealthStatus] = useState('')
    const [icon, setIcon]=useState('')
    const [img, setImg]=useState(LoadingIcon)
    
    // const { auth, dispatchAuth } = useContext(AuthContext)
    //  const { auth, dispatchAuth } = useContext(AuthContext)
    useEffect(() => {
        document.getElementById("scan").click();

        // return () => {
        //     document.getElementById("stop").click();
            
        // }
        
    }, [])

     useEffect(() => {
  const interval = setInterval(() => {
    // console.log('This will run every second!');
      
      getVal()
  }, 2000);
  return () => clearInterval(interval);
    }, []);

    useEffect(()=>{
        setIcon('')
        setImg('')

    },[])

    useEffect(()=>{
        renderstatus()

    },[healthstatus])


    const getVal=()=>{
       var status= document.getElementById("diag_value").value

    //    document.getElementById("demo").innerHTML = status;

       console.log(status)
       if(status!=healthstatus){
        setHealthStatus(status)
       }
    }


    const renderstatus=()=>{

        if(healthstatus==0){
                setImg(Healthy)
                setIcon(HealthyMarker)
            

            return 

        }else if(healthstatus==1){
            //LoadingIcon
            // caution
            setImg(Caution)
            setIcon(CautionMarker)
        }
        else if(healthstatus==2){
            //LoadingIcon
            // abnormal
            setImg(Abnormal)
            setImg(AbnormalMarker)
        }else{
            setImg(LoadingIcon)
            setIcon('')

        }


    }
    


    
   
    const reClick = () => {
        // alert('hello');
        // document.getElementById("scan").click();
        // document.getElementById("scan").click();

        // NotificationManager.info('Loading....');
        

        // openDialog2()


        

        // setTimeout(function () {
        //     reClick();
        // }, 5000);
    }
    
    return (<div className=" mx-auto ml-64">
        <div class="rounded-lg flex flex-col md:flex-row sm:w-full bg-white shadow-2xl md:ml-64 lg:ml-80">
            {/* <div id="demo"></div> */}
            <div class="w-50 md:ml-5">
                {/* 2 */}
                {/* {healthstatus} */}
                
                <div id="vital_history" > 
                    {/* <h4> Diagnosis :  <b id="diagnosis" ></b></h4> */}
                    
                </div>
                <br />
                <div className="wrapper">
                <div>
                    <div className="navi bg-black">
                    
                    {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
                    <canvas id="canvas" width="640" height="480"></canvas> */}
                    <video hidden id="webcam"  width="640" className='w-full' height="480" autoPlay></video>
        <canvas id="canvas"  width="640" className='w-full' height="480"></canvas>
                    
                    
                    </div>
                </div>
                <div className=" w-full h-full grid grid-flow-row grid-cols-2" id="infoi">

                  <div className="flex flex-col m-4">
                      {/* Left part */}

                      <div className="bg-no-repeat  h-28 bg-contain" style={{ backgroundImage: `url(${Spo2})`}} >
                      
                      <img src={icon} className=" h-3 ml-12" />



                          < br />
                         
                          <div className='flex flex-col'>
                          <input  className=" bg-inherit border-0 w-12 mx-8 text-white text-3xl text-extrabold font-black mt-1" readOnly id="spo2_rate" /> 
                          <div>
                          <span className='text-white font-bold ml-10 mt-18'>%</span>
                            </div>
                          </div>

                          
                      </div>
                      <span className="text-white font-bold ml-2" >SpO <sup>2</sup>Levels</span>
                      <div className="bg-no-repeat h-28 bg-contain" style={{ backgroundImage: `url(${HeartRate})`}} >
                      <img src={icon} className=" h-3 ml-12" />
                          < br />
                          <div className='flex flex-col'>
                          <input type="text" className=" bg-inherit border-0 w-16 mx-8 text-white text-3xl text-extrabold font-black mt-1" readOnly id="heart_rate" />
                        <span className='text-white font-bold ml-10 mt-18'>BPM</span>

                          </div>
                        
                      </div>
                      <span className=" text-white font-bold ml-3" >Heart Rate</span>
                      {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BsRate})`}} >
                          < br />
                        <input className=" bg-inherit border-0 w-10 mx-6 text-white text-2xl text-extrabold font-black mt-1" readOnly id="bs_rate" /> 
                        <br />
                        <span className='text-white font-bold ml-3'>mg/dL</span> 
                      </div>
                        <span className="text-sm text-white font-bold" >Blood Sugar</span> */}
                      
                  </div>
                  <div className="flex flex-row-reverse mt-4">
                            {/* right part */}
                            

                      <div className="flex flex-col">
                          <div>
                          {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BPressure})`}} >
                          < br />
                          
                            <span className="mt-10 text-white"> <input className=" bg-inherit border-0 w-10 text-white text-extrabold font-black mt-1"  readOnly id="syst_rate" /> <b>- </b> / <input className=" bg-inherit border-0 w-10 text-white text-extrabold font-black mt-1" readOnly id="diast_rate" />  </span>
                            <br /> <span className='text-white font-bold ml-6'>mmHg</span> 
                          
                       
                      </div>
                      <span className="text-sm text-white font-bold mr-3" >Blood Pressure</span> */}

                      </div>
                      <div>
                          

                      </div>
                      <div>
                          <div className="bg-no-repeat h-28 bg-contain mr-4" style={{ backgroundImage: `url(${Breathing})`}} >
                          <img src={icon} className=" h-3 ml-12 mb-2" />
                          < br />
                         
                          
                            <span className=" mt-10 text-white"> <input className=" bg-inherit border-0 w-12 mx-8 text-white text-3xl text-extrabold font-black mt-1" readOnly id="br_rate" /> </span>
                            <br /> 
                            {/* <br />  */}
                            <span className='text-white font-bold ml-10 mt-18'>RPM</span> 
                          
                          
                      </div>
                      <span className=" text-white font-bold text-1xl" >Breathing Rate</span>

                      </div>
                      <div>
                          <img src={img} className=" h-20" />

                      </div>

                      </div>
                      
                      
                  </div>

              </div>
  </div>
  <div className="flex m-3">
      <div className='space-x-4 flex flex-row'>
          {/* <button id="scan" onClick={()=>reClick()} className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Start Scanning</button> */}
          {/* <button onClick={()=>reClick()}>Test Click</button> */}

          <button hidden id="scan" className='btn'>Start Idle Scanning</button>
                    <button id="real_scan" className='btn btn-info'>Start Vital Scanning</button>
                    <button id="stop"  className='btn btn-success'>Stop Vital Scanning</button>
    
        
      </div>
      <div className='ml-6'>
          <div>

          <div class="col-sm hidden">
          <img hidden id="spo2_img" src="./vitalbox.png" alt="" /> <canvas class="spo2_canvas" width="480" height="130"></canvas><canvas class="spo2_canvas" width="480" height="130"></canvas>
    <img hidden id="br_img" src="./vitalbox.png" alt="" /> <canvas class="br_canvas" width="480" height="130"></canvas><canvas class="br_canvas" width="480" height="130"></canvas>
    <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas class="hr_canvas" width="480" height="130"></canvas><canvas class="hr_canvas" width="480" height="130"></canvas>
    </div>
              {/* <button id="stop" disabled className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Stop Scanning</button> */}
          </div>
      </div>
  </div>
  {/* <span onClick={(e)=>getVal(e)}>
      <button  id="diagnosis" >Get val</button>
      
  </span> */}
  
  
  <h4> Diagnosis :  <input type="text" id="diagnosis" /><input type="text" hidden id="diag_value" /></h4>
        
            </div>
            
        </div>
        

       
    </div>)
}
 
export default Security;